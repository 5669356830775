// normalize CSS across browsers
import './static/styles/normalize.css';
// custom CSS styles
import './static/styles/layout.css';
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
const React = require(`react`);
const { StoreProvider } = require(`./src/context/ShopifyStoreContext`);

export const wrapRootElement = ({ element }) => {
  return <StoreProvider>{element}</StoreProvider>;
};
